<template>
    <div>
         <staticResourceTabs
                :isValid="isValid"
                v-on="$listeners"
                :type="valueLine.parameters.target.type"
        >
           <template v-slot:tabsContent="data">
                <div class="mb-n9" v-if="data.item == 'static'">
                    <sipCallStaticContent
                            :localStoredStep="valueLine"
                            v-on="$listeners"
                            :result="result"
                            :isValid="isValid"
                            :hideDetails="false"
                    ></sipCallStaticContent>
                </div>
                <div class="mb-n9" v-if="data.item == 'resource'">
                     <sipCallResourceContent
                            :localStoredStep="valueLine"
                            v-on="$listeners"
                            :result="result"
                            :isValid="isValid"
                            :hideDetails="false"
                    ></sipCallResourceContent>
                </div>
            </template>
        </staticResourceTabs>
        <div>
            <fieldsRows
                    :templateContent="templateContent"
                    @field-value-changed="updateFieldValue(...arguments)"
                    :valueLine="valueLine['parameters']"
                    :isValid="isValid"
                    @revalidate="$emit('revalidate')"
            ></fieldsRows>
        </div>
    </div>
</template>
<script>
  import staticResourceTabs from "@/components/legacy/staticResourceTabs.vue";
  import sipCallStaticContent from "@/components/legacy/sipCallStaticContent.vue";
  import sipCallResourceContent from "@/components/legacy/sipCallResourceContent.vue";
  import fieldsRows from "@/commonComponents/fieldsRows.vue";

  export default {
    data() {
      return {
      };
    },
    components: {
      staticResourceTabs,
      sipCallStaticContent,
      sipCallResourceContent,
      fieldsRows
    },
    props: {
       result:{},
       field: String,
       isValid: Boolean,
       valueLine: {},
       value: {},
       codecs:[]
    },
    methods: {
      updateFieldValue(index, value) {
       // console.log("--->",index,value);
        if(index==="checkAudioQuality"){
          if(value===0){
            value=false;
          }else{
            value=true;
          }
        }
        if(index!="testDirection"){
          this.$set(this.valueLine['parameters'], index, value);
          this.$emit("stored-value-changed", this.valueLine);
        }
      },
    },
    computed: {
      templateContent() {
        let templateContent = {
          fields: {
            testDirection: {
              inputFieldFormatter: "sipCallParameters",
            },
          },
          stepType: this.stepType,
          stepID: this.stepID,
          disableAllInputs: this.result.disableAllInputs,
          enableAdd: false,
          contentID: this.result.contentID,
          codecs:this.result.codecs
        };
        return templateContent;
      },
    },
  };
</script>