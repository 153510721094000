<template>
  <div>
    <v-row>
      <v-col cols="12">
        <sipHostValidatedInput
            field="host"
            :fieldAttributes="{name:'SIP Address'}"
            v-model="host"
            :valueLine="this.localStoredStep.parameters.target"
            :result="result"
            :isValid="isValid"
            :required="this.localStoredStep.parameters.target.type == 'static'"
        >
        </sipHostValidatedInput>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import sipHostValidatedInput from "@/components/dynamic/sipHostValidatedInput.vue";

export default {
  props: ["localStoredStep", "result", "isValid"],
  data: function () {
    return {
     };
  },
  components: {
    sipHostValidatedInput
  },
  computed: {
    evalValue: {
      get() {
        let evalValue = {};
        if (
          typeof this.localStoredStep["parameters"] != "undefined" &&
          typeof this.localStoredStep["parameters"]["target"] != "undefined"
        ) {
          evalValue = this.localStoredStep["parameters"]["target"];
        }
        return evalValue;
      },
      set(newVal) {
        this.updateResponseKey("target", newVal);
      },
    },
    host: {
      get() {
        let host = "";
        if (typeof this.evalValue["host"] != "undefined") {
          host = this.evalValue["host"];
        } else if (
          typeof this.localStoredStep["parameters"].host != "undefined"
        ) {
          host = this.localStoredStep["parameters"].host;
        }
        return host;
      },
      set(newVal) {
          this.$set(this.evalValue, "host", newVal);
          this.updateResponseKey("target", this.evalValue);
      },
    },
  },
  methods: {
    updateResponseKey(index, newVal) {
      this.$set(this.localStoredStep["parameters"], index, { ...newVal });
      this.$emit("stored-value-changed", this.localStoredStep);
    },
  },
};
</script>