<template>
  <div>
    <primaryTextfield
      type="text"
      :fieldAttrInput="{
        class: inputClass,
        id: result.contentID + '_'+field,
      }"
      :label="fieldAttributes.name"
      v-model="host"
      :templateContent="result"
      :isValid="isValid"
    ></primaryTextfield>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
export default {
  components: { primaryTextfield },
  props: {
    field: {},
    fieldAttributes: {},
    value: {},
    valueLine: {},
    result: {},
    isValid: {},
    required: { type: Boolean, default: true },
  },

  watch: {

  },
  computed: {
    host: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    inputClass() {
      let ic = "";
      if (this.required) {
        ic = "required";
      }
      return ic;
    },
  },
};
</script>